import { urlEndPoint } from "../Config";
import { callApi, getToken } from "./utils";


export const apiGetCompany = async (requestData) => {
    const url = `${urlEndPoint}/api/v1/drivers-company`;
    const json = await callApi(url, 'GET', getToken(), null, requestData);
    return json;
  };
  
  export const apiGetAllDrivers = async (requestData) => {
    const url = `${urlEndPoint}/api/v1/drivers`;
    const json = await callApi(url, 'GET', getToken(), null, requestData);
    return json;
  };
  export const apiGetDriversByID = async (driver_id) => {
    const url = `${urlEndPoint}/api/v1/drivers/ById/${driver_id}/`;
    const json = await callApi(url, 'GET', getToken(), null, driver_id);
    return json;
  };
  export const apiDelDriver = async (driver_id) => {
    const url = `${urlEndPoint}/api/v1/drivers/${driver_id}/`;
    const json = await callApi(url, 'DELETE', getToken(), driver_id);
    return json;
  };
  export const apiUpdateDriver = async (driver_id, requestData) => {
    const url = `${urlEndPoint}/api/v1/drivers/${driver_id}`;
    const json = await callApi(url, 'PUT', getToken(), requestData);
    return json;
  };
  export const createDrivers = async (body) => {
    const url = `${urlEndPoint}/api/v1/drivers/`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  };