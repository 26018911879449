import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import PrivateRouter from './Private';

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
);
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/autoexpertos/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/autoexpertos/authentication/Login')));
const Recovery = Loadable(lazy(() => import('../views/autoexpertos/authentication/ResetPassword')));
const Close = Loadable(lazy(() => import('../views/autoexpertos/authentication/CloseComponent')));

/* ****Pages***** */
const Transfer = Loadable(lazy(() => import('../views/autoexpertos/transfer')));
const Calendar = Loadable(lazy(() => import('../views/autoexpertos/calendar/ACalendar')));
const Driver = Loadable(lazy(() => import('../views/autoexpertos/drivers')));
const Pricing = Loadable(lazy(() => import('../views/autoexpertos/price')));
const Booking = Loadable(lazy(() => import('../views/autoexpertos/booking')));
const Finished = Loadable(lazy(() => import('../views/autoexpertos/finished')));

/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      {
        path: '/transfers',
        exact: true,
        element: <PrivateRouter component={<Transfer />} />,
      },
      {
        path: '/transfers/detail/:otId',
        element: (
          <PrivateRouter
            component={<Booking />}
            profile={[0, 1, 2, 4, 5]}
          />
        ),
      },
      {
        path: '/finished',
        exact: true,
        element: (
          <PrivateRouter component={<Finished />} profile={[0, 1, 2, 4, 5]} />
        ),
      },
      {
        path: '/calendar',
        exact: true,
        element: (
          <PrivateRouter component={<Calendar />} profile={[0, 1, 2, 4, 5]} />
        ),
      },
      {
        path: '/pricing',
        exact: true,
        element: (
          <PrivateRouter component={<Pricing />} profile={[0, 1, 2, 4, 5]} />
        ),
      },
      {
        path: '/drivers',
        exact: true,
        element: (
          <PrivateRouter component={<Driver />} profile={[0, 1, 2, 4, 5]} />
        ),
      },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'close', element: <Close /> },
      { path: 'recovery/:token', element: <Recovery /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
