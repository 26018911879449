import { urlEndPoint } from "../Config";
import { callApi, callSendFornData, getToken } from "./utils";

/**
 * The function `ApiUploadPhotoAdditional` uploads a photo using a form data object.
 * @param formData - The `formData` parameter is an object that contains the data to be sent in the
 * request body. It is typically used for sending files or binary data. In this case, it is used to
 * send an image file to the server for uploading.
 * @returns a JSON object.
 */
export const ApiUploadPhotoAdditional = async (formData) => {
    const url = `${urlEndPoint}/api/v1/additional/uploadImage`;
    const json = await callSendFornData(url, getToken(), formData);
    return json;
  };
  
  /**
   * The function `apiCreateAdditional` sends a POST request to create additional data using an API.
   * @param body - The `body` parameter is the data that you want to send in the request body. It can be
   * an object or a string that represents the data you want to send to the server.
   * @returns a JSON object.
   */
  export const apiCreateAdditional = async (body) => {
    const url = `${urlEndPoint}/api/v1/additional/create`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  };
  
  export const apiEditAdditional = async (body) => {
    const url = `${urlEndPoint}/api/v1/additional/edit`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  };

  /**
   * The function `apiGetAdditionalAll` makes an API call to retrieve additional information for a given
   * maintenance ID.
   * @param maintenanceId - The `maintenanceId` parameter is the identifier for a specific maintenance
   * record. It is used to retrieve additional information related to that maintenance record.
   * @returns a JSON object.
   */
  export const apiGetAdditionalAll = async (maintenanceId) => {
    const url = `${urlEndPoint}/api/v1/additional/getAdditional/${maintenanceId}`;
    const json = await callApi(url, 'GET', getToken(), null);
    return json;
  };
  
  /**
   * The function `apiGetAdditionalImagen` makes an API call to retrieve additional images for a
   * maintenance additional item.
   * @param maintenanceAdditionalId - The `maintenanceAdditionalId` parameter is the ID of the
   * maintenance additional for which you want to retrieve additional images.
   * @returns a JSON object.
   */
  export const apiGetAdditionalImagen = async (maintenanceAdditionalId) => {
    const url = `${urlEndPoint}/api/v1/additional/getAdditionalImages/${maintenanceAdditionalId}`;
    const json = await callApi(url, 'GET', getToken(), null);
    return json;
  };
  
  /**
   * The function `apiDelAdditional` sends a DELETE request to the specified URL with the provided body
   * and returns the response as JSON.
   * @param body - The `body` parameter is an object that contains the data to be sent in the request
   * body. It is used to provide additional information or data to the server when making a DELETE
   * request to the `/api/v1/additional/delAdditional` endpoint.
   * @returns a JSON object.
   */
  export const apiDelAdditional = async (body) => {
    const url = `${urlEndPoint}/api/v1/additional/delAdditional`;
    const json = await callApi(url, 'DELETE', getToken(), body);
    return json;
  };
  
  /**
   * The function `apiSendApprovedAdditional` sends a POST request to the specified API endpoint with the
   * provided body and returns the response as JSON.
   * @param body - The `body` parameter is an object that contains the data to be sent in the request
   * body. It is used to provide additional information or data to the server when making the API call.
   * @returns the JSON response from the API call.
   */
  export const apiSendApprovedAdditional = async (body) => {
    const url = `${urlEndPoint}/api/v1/additional/requestApproval`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  };