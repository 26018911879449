import React, { lazy, useEffect } from 'react';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import {
  UserData,
  descryptedaes256cbc,
  encryptedaes256cbc,
  getTokenView,
} from '../apis/utils';
import { apiGetVerifyToken } from '../apis';
import { Grid, Typography } from '@mui/material';
import LoadingAnimation from '../assets/98642-error-404.json';
import Lottie from 'lottie-react';





const PrivateRouter = (props) => {
  const noView = Loadable(
    lazy(() => import('../views/autoexpertos/NoView/noview'))
  );

  let credencial = '';
  const localToken = getTokenView(localStorage.getItem('session'));
  let validated = true;
  let validatedView = true;
  if (process.env.REACT_APP_AAA === 'true') {
    if (!localStorage.getItem('cea')) {
      validated = false;
    } else {
      try {
        credencial = {
          data: descryptedaes256cbc(localStorage.getItem('cea'), localToken),
        };
        validated = true;
      } catch (error) {
        // console.log(error);
        validated = false;
      }

      //con token verificamos que este vigente
    }

    let StoreIDcreate = true;
    if (UserData().access && !UserData().storeId) {
      StoreIDcreate = false;
    } else {
      StoreIDcreate = true
    }

    // console.log("StoreIDcreate", StoreIDcreate)

    if (validated) {
      if (validatedView) {
        if (StoreIDcreate) {
          return props.component;
        } else {
          return (

            <Grid container spacing={0} style={{
              width: "100 %",
              height: "100vh",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center"
            }}><Typography style={{ fontSize: "24px", fontWeight: "700" }}>Selecciona un Taller</Typography></Grid>
          )
        }
      } else {
        return (
          <Grid container spacing={0}>
            {/* ------------------------- row 1 ------------------------- */}

            <Grid item xs={12} lg={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '90%',
                  height: '60vh',
                }}
              >
                <Lottie animationData={LoadingAnimation} />
              </div>
            </Grid>
          </Grid>
        );
        //MOSTRAR COMPONENTE SIN PERMISOS
      }
    } else {
      //window.location.href = './';
    }
  }

  //check credencial or link to navigator login
};

export default PrivateRouter;

export const IsEditPermition = () => {
  const localToken = getTokenView(localStorage.getItem('session'));
  const credencial = {
    data: descryptedaes256cbc(localStorage.getItem('aet'), localToken),
  };

  const profileUser = credencial.data.profile;
  // console.log("profileUser", profileUser)
  if (profileUser === 1 || profileUser === 0) {
    return true;
  } else {
    return false;
  }
};

export const CodProfile = () => {
  const localToken = getTokenView(localStorage.getItem('session'));
  const credencial = {
    data: descryptedaes256cbc(localStorage.getItem('aet'), localToken),
  };

  const profileUser = credencial.data.profile;
  // console.log("profileUser", profileUser)

  return profileUser;
};
