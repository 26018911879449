import { urlEndPoint } from "../Config";
import { callApi, callSendFornData, getToken } from "./utils";

export const ApiUploadPhotoOrder = async (formData) => {
    const url = `${urlEndPoint}/api/v1/order/uploadImage`;
    const json = await callSendFornData(url, getToken(), formData);
    return json;
  };
  
  export const apiDelPhotoOrder = async (body) => {
    const url = `${urlEndPoint}/api/v1/order/deleteImage`;
    const json = await callApi(url, 'DELETE', getToken(), body);
    return json;
  };
  
  export const apiGetAllPhotoOrder = async (maintenanceId) => {
    const url = `${urlEndPoint}/api/v1/order/getAllImage/${maintenanceId}`;
    const json = await callApi(url, 'GET', getToken(), null);
    return json;
  };
  
  export const apiGetOrderNext = async (body) => {
    const url = `${urlEndPoint}/api/v1/order/next`;
    const json = await callApi(url, 'POST', getToken(), body);
    return json;
  };