import { urlEndPoint } from "../Config";
import { callApi, callSendFornData, getToken } from "./utils";


/**
 * The function `AddReception` sends a POST request to register a reception using the provided
 * reception data.
 * @param receptionData - The `receptionData` parameter is an object that contains the data needed to
 * register a reception. It should include properties such as the receptionist's name, the date and
 * time of the reception, the purpose of the reception, and any other relevant information.
 * @returns the JSON response from the API call.
 */
export const AddReception = async (receptionData) => {
    const url = `${urlEndPoint}/api/v1/reception/register`;
    const json = await callApi(url, 'POST', getToken(), receptionData);
    return json;
  };
  
  /**
   * The function `apiGetAllPhotoReception` makes an API call to retrieve all images associated with a
   * maintenance ID.
   * @param maintenanceId - The `maintenanceId` parameter is the identifier for a specific maintenance
   * task or job. It is used to retrieve all the photo reception images associated with that maintenance
   * task.
   * @returns a JSON object.
   */
  export const apiGetAllPhotoReception = async (maintenanceId) => {
    const url = `${urlEndPoint}/api/v1/reception/getAllImage/${maintenanceId}`;
    const json = await callApi(url, 'GET', getToken(), null);
    return json;
  };

  /**
 * The function `apiDelPhotoReception` sends a DELETE request to the specified URL with the provided
 * body and returns the response as JSON.
 * @param body - The `body` parameter is an object that contains the necessary data for the API
 * request. It could include information such as the ID of the photo to be deleted or any other
 * relevant data required by the API endpoint.
 * @returns a JSON object.
 */
export const apiDelPhotoReception = async (body) => {
    const url = `${urlEndPoint}/api/v1/reception/deleteImage`;
    const json = await callApi(url, 'DELETE', getToken(), body);
    return json;
  };


/**
 * The function `ApiUploadPhotoRecepcion` is an asynchronous function that uploads a photo to a
 * specific API endpoint and returns the response as JSON.
 * @param formData - The `formData` parameter is an object that contains the data to be sent in the
 * request body. It is typically used for sending files or binary data. In this case, it is being used
 * to send an image file for uploading.
 * @returns a JSON object.
 */
export const ApiUploadPhotoRecepcion = async (formData) => {
    const url = `${urlEndPoint}/api/v1/reception/uploadImage`;
    const json = await callSendFornData(url, getToken(), formData);
    return json;
  };
  