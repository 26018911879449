import { urlEndPoint } from "../Config";
import { callApi, callApiInit, callApiLogin, callApiRegister, descryptedaes256cbc, encryptedaes256cbc, getToken, getTokenView } from "./utils";



//VERIFY TOKEN
export const apiGetVerifyToken = async () => {
    const url = `${urlEndPoint}/api/v1/users/verify`;
    const json = await callApi(url, 'GET', getToken(), null);
  
    return json;
  };
  
  //SESSION TOKEN
  export const apiGetSession = async () => {
    const url = `${urlEndPoint}/api/v1/auth/init`;
    const json = await callApiInit(url, 'GET', null, null);
    //console.log(json);
    return json;
  };
  
  /**
   * The function `apiVerifyTokenRecovery` sends a POST request to the specified URL with the provided
   * body and returns the response as JSON.
   * @param body - The `body` parameter is an object that contains the data to be sent in the request
   * body. It typically includes information required for token recovery, such as the user's email or
   * username.
   * @returns the JSON response from the API call.
   */
  export const apiVerifyTokenRecovery = async (body) => {
    const url = `${urlEndPoint}/api/v1/auth/token/recovery`;
    const json = await callApi(url, 'POST', null, body);
    return json;
  };
  
  //LOGIN
  export const apiLogin = async (requestData) => {
    const url = `${urlEndPoint}/api/v1/auth/signin`;
    const json = await callApiLogin(url, 'POST', null, requestData);
    return json;
  };
  
  export const getRecoveryPass = async (requestData) => {
    const url = `${urlEndPoint}/api/v1/auth/recovery`;
    const json = await callApi(url, 'POST', null, requestData);
    return json;
  };

  export const apiGetLogin = async (body) => {
    const url = `${urlEndPoint}/api/v1/auth/signin`;
    const json = await callApiLogin(url, 'POST', null, body);
    // console.log(json)
    return json;
  };

  export const apiRegisterLogin = async (body) => {
    const url = `${urlEndPoint}/api/v1/auth/signup`;
    const json = await callApiRegister(url, 'POST', getToken(), body);
    return json;
  };

  export const apiUpdatePass = async (body) => {
    const url = `${urlEndPoint}/api/v1/auth/token/pass`;
    const json = await callApi(url, 'PUT', getToken(), body);
    return json;
  };

  export const apiGetAllStoreAdmin = async (body) => {
    const url = `${urlEndPoint}/api/v1/users/get/all/store`;
    const json = await callApi(url, 'GET', getToken(), body);
    return json;
  };
  
  
  export const apiGetNewToken = async (body) => {
    const url = `${urlEndPoint}/api/v1/users/get/new/token`;
    const json = await callApi(url, 'POST', getToken(), body);
    // console.log('json', json);
    return json;
  };
