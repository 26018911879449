
import { urlEndPoint } from "../Config";
import { callApi, getToken } from "./utils";

export const apiGetTranfers = async (requestData) => {
    const url = `${urlEndPoint}/api/v1/transfers`;
    const json = await callApi(url, 'GET', getToken(), null, requestData);
    return json;
  };
  export const apiGetTranfersByID = async (id) => {
    const url = `${urlEndPoint}/api/v1/transfers/${id}`;
    const json = await callApi(url, 'GET', getToken(), null);
    return json;
  };
  
  export const apiUpdatetrasferById = async (transferserviceId,data ) => {
    const url = `${urlEndPoint}/api/v1/transfers/${transferserviceId}`;
    const json = await callApi(url, 'PUT', getToken(),data);
    return json;
  };