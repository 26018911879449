import { ReactComponent as LogoDark } from './assets/autoexpertos/logo.svg';
import { ReactComponent as LogoLight } from './assets/autoexpertos/logo.svg';

let Menuitems = [
  {
    title: 'Traslados',
    icon: 'trending-up',
    href: '/transfers',
  },
  {
    title: 'Finalizados',
    icon: 'check',
    href: '/finished',
  },
  {
    title: 'Calendario',
    icon: 'calendar',
    href: '/calendar',
  },
  // {
  //   title: 'Precios',
  //   icon: 'dollar-sign',
  //   href: '/pricing',
  // },
  // {
  //   title: 'Reportes',
  //   icon: 'pie-chart',
  //   href: '/reports',
  // },
  ,{
    title: 'Choferes',
    icon: 'user',
    href: '/drivers',
  },
  {
    title: 'Cerrar Sesión',
    icon: 'log-out',
    href: '/auth/close',
  },
];
const nameApp = 'Drivers - AutoExpertos';
const urlEndPoint = process.env.REACT_APP_API;
const forceLogin = true;

export { LogoLight, LogoDark, Menuitems, nameApp, urlEndPoint };
